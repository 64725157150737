* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;

    font-size: 20px;
}

.App {
    text-align: center;
}

.input {
    font-size: 20px;
    max-width: 200px;
    padding: 10px;
    border-radius: 10px;
    color: black;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    margin: 10px;
}

p {
    font-size: 20px;
    color: black;
    margin: 5px;
}
